import React from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "../redux/slices/auth/authActions";
import { IconButton } from "@mui/material";
import { SignOut } from "phosphor-react";
import { removeWebSocketTicket } from "../webSocket/websocket";
import { webSocketService } from "../webSocket/websocket";
import { getAuth, signOut } from "firebase/auth";

const logout = () => {
  return { type: 'USER_LOGOUT' };
};

const LogoutButton = () => {
  const dispatch = useDispatch();
  const auth = getAuth();


  const handleLogout = () => {
    // Clear cookies
    signOut(auth).then(() => {
      console.log("User signed out successfully.");
      // Redirect to login page
      window.location.href = "/login";
    }).catch((error) => {
      console.error("Error signing out:", error);
    });
    dispatch(logoutUser());
    dispatch(logout());
    removeWebSocketTicket();
    webSocketService.close();

  };

  return (
    <IconButton onClick={handleLogout} variant="contained" color="secondary">
      <SignOut color="#FF0000" />
    </IconButton>
  );
};

export default LogoutButton;