import { faker } from "@faker-js/faker";
import {
  Bell,
  ChatCircleDots,
  Gear,
  GearSix,
  House,
  Phone,
  SignOut,
  User,
  UserPlus,
  Users,
} from "phosphor-react";

const Profile_Menu = [
  {
    title: "Profile",
    icon: <User />,
  },
  {
    title: "Settings",
    icon: <Gear />,
  },
  {
    title: "Profile",
    icon: <SignOut />,
  },
];

const Nav_Buttons = [
  {
    index : 4,
    icon: <House/>
  },
  {
    index: 0,
    icon: <ChatCircleDots />,
  },
  {
    index: 1,
    icon: <Users />,
  },
  // {
  //   index: 2,
  //   icon: <Phone />,
  // },
  {
    index: 5,
    icon: <Bell/>
  },
  {
    index:3,
    icon: <UserPlus/>
  },

];

const Nav_Setting = [
  {
    index: 3,
    icon: <GearSix />,
  },
];

// const ChatList = [
//   {
//     id: 0,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "9:36",
//     unread: 0,
//     pinned: true,
//     online: true,
//   },
//   {
//     id: 1,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "12:02",
//     unread: 2,
//     pinned: true,
//     online: false,
//   },
//   {
//     id: 2,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "10:35",
//     unread: 3,
//     pinned: false,
//     online: true,
//   },
//   {
//     id: 3,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "04:00",
//     unread: 0,
//     pinned: false,
//     online: true,
//   },
//   {
//     id: 4,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "08:42",
//     unread: 0,
//     pinned: false,
//     online: false,
//   },
//   {
//     id: 5,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "08:42",
//     unread: 0,
//     pinned: false,
//     online: false,
//   },
//   {
//     id: 6,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "08:42",
//     unread: 0,
//     pinned: false,
//     online: false,
//   },
//   {
//     id: 7,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "08:42",
//     unread: 0,
//     pinned: false,
//     online: false,
//   },
//   {
//     id: 7,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "08:42",
//     unread: 0,
//     pinned: false,
//     online: false,
//   },
//   {
//     id: 7,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "08:42",
//     unread: 0,
//     pinned: false,
//     online: false,
//   },
//   {
//     id: 7,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "08:42",
//     unread: 0,
//     pinned: false,
//     online: false,
//   },
//   {
//     id: 7,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "08:42",
//     unread: 0,
//     pinned: false,
//     online: false,
//   },
//   {
//     id: 7,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "08:42",
//     unread: 0,
//     pinned: false,
//     online: false,
//   },
//   {
//     id: 7,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "08:42",
//     unread: 0,
//     pinned: false,
//     online: false,
//   },
//   {
//     id: 7,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "08:42",
//     unread: 0,
//     pinned: false,
//     online: false,
//   },
//   {
//     id: 7,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "08:42",
//     unread: 0,
//     pinned: false,
//     online: false,
//   },
//   {
//     id: 7,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "08:42",
//     unread: 0,
//     pinned: false,
//     online: false,
//   },
//   {
//     id: 7,
//     img: faker.image.avatar(),
//     name: faker.person.firstName(),
//     msg: faker.music.songName(),
//     time: "08:42",
//     unread: 0,
//     pinned: false,
//     online: false,
//   },
// ];

// const Chat_History = [
//   {
//     type: "msg",
//     message: "Hi 👋🏻, How are ya ?",
//     incoming: true,
//     outgoing: false,
//   },
//   {
//     type: "divider",
//     text: "Today",
//   },
//   {
//     type: "msg",
//     message: "Hi 👋 Panda, not bad, u ?",
//     incoming: false,
//     outgoing: true,
//   },
//   {
//     type: "msg",
//     message: "Can you send me an abstarct image?",
//     incoming: false,
//     outgoing: true,
//   },
//   {
//     type: "msg",
//     message: "Ya sure, sending you a pic",
//     incoming: true,
//     outgoing: false,
//   },

//   {
//     type: "msg",
//     subtype: "img",
//     message: "Here You Go",
//     img: faker.image.abstract(),
//     incoming: true,
//     outgoing: false,
//   },
//   {
//     type: "msg",
//     message: "Can you please send this in file format?",
//     incoming: false,
//     outgoing: true,
//   },

//   {
//     type: "msg",
//     subtype: "doc",
//     message: "Yes sure, here you go.",
//     incoming: true,
//     outgoing: false,
//   },
//   {
//     type: "msg",
//     subtype: "link",
//     preview: faker.image.cats(),
//     message: "Yep, I can also do that",
//     incoming: true,
//     outgoing: false,
//   },
//   {
//     type: "msg",
//     subtype: "reply",
//     reply: "This is a reply",
//     message: "Yep, I can also do that",
//     incoming: false,
//     outgoing: true,
//   },
// ];

// const Message_options = [
//   {
//     title: "Reply",
//   },
//   {
//     title: "React to message",
//   },
//   {
//     title: "Forward message",
//   },
//   {
//     title: "Star message",
//   },
//   {
//     title: "Report",
//   },
//   {
//     title: "Delete Message",
//   },
// ];

// const UserList = [
//   {
//     userId : "3rGo9gKXiyRjVka2WT4rnq3RMXD3",
//     UserName : "user1",
//     img : faker.image.avatar(),
//   },
//   {
//     userId : "IUgPtuiAxtOWkHb0yvyITOyKBjz2",
//     UserName : "user2",
//     img : faker.image.avatar(),
//   },
//   {
//     userId : "fzKKofvtVQSnZL2MghuLUEgS1mS2",
//     UserName : "user3",
//     img : faker.image.avatar(),
//   },
//   {
//     userId : "eec76lNZOlcqitH138b10mhTrS12",
//     UserName : "user4",
//     img : faker.image.avatar(),
//   },

// ];

export {
  Profile_Menu,
  Nav_Setting,
  Nav_Buttons,
  // ChatList,
  // Chat_History,
  // Message_options,
  // UserList,
};
