import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   events : []
  };

const usersSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    addEvent(state, action) {
      console.log("adding event");
      const { id, conversation_id, start, end, title, description,allDay ,free ,color , participants } = action.payload;
      const index = state.events.findIndex(event => event.id === id);
      if (index !== -1) {
        // Update existing event
        state.events[index] = { id, conversation_id, start, end, title, description, allDay, free, color, participants };
      } else {
        // Add new event
        state.events.push({ id, conversation_id, start, end, title, description, allDay, free, color, participants });
      }
    },

    removeEvent(state, action) {
        const { id } = action.payload;
        state.events = state.events.filter(event => event.id !== id);
    },

    updateEvent(state, action) {
        const { id, start, end, title, description, allDay, free, color } = action.payload;
        const existingEvent = state.events.find(event => event.id === id);
        if (existingEvent) {
          existingEvent.start = start;
          existingEvent.end = end;
          existingEvent.title = title;
          existingEvent.description = description;
          existingEvent.allDay = allDay;
          existingEvent.free = free;
          existingEvent.color = color;
        }
      }
  }
});

export const { addEvent , removeEvent ,updateEvent } = usersSlice.actions;
export default usersSlice.reducer;
