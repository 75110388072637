// snackbarSlice.js
import { createSlice } from '@reduxjs/toolkit';


const initialState = {
notifications : {},
sortedNotifications : []
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action) => {
        
        const { type, id, data , time } = action.payload;
        const updatedPayload = {
          ...data,
          type: type,
          notificationId : id, 
        };
        console.log("addNotification data = ", data);
        state.notifications[id] = updatedPayload;
        const exists = state.sortedNotifications.some(el => el[0] === id);
        if(!exists){
          state.sortedNotifications = [...state.sortedNotifications, [id,time]];
        }
        
        state.sortedNotifications.sort((a, b) => a[1] - b[1]);
        state.sortedNotifications = state.sortedNotifications.filter(element => element[0] !== undefined);
    },

    removeNotification: (state, action) => {

        const id = action.payload.id;
        delete state.notifications[id];
    }

   
  },
});

export const {addNotification, removeNotification} = notificationsSlice.actions;
export default notificationsSlice.reducer;
