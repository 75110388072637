import { faker } from "@faker-js/faker";

class Message {
  constructor(
    messageId,
    senderId,
    receiverId,
    text,
    sentAt,
    receivedAt,
    isSeen,
    type,
    isOutgoing,
    subtype,
    metadata
  ) {
    this.messageId = messageId;
    this.senderId = senderId;
    this.receiverId = receiverId;
    this.text = text;
    this.sentAt = sentAt || new Date();
    this.receivedAt = receivedAt || null;
    this.isSeen = isSeen || false;
    this.type = type;
    this.isOutgoing = isOutgoing;
    this.subtype = subtype;
    this.metadata = metadata || null;
  }
}

class Conversation {
  constructor(
    conversationId,
    ConvType,
    userIds,
    messages,
    lastMessage,
    img,
    name,
    presense,
    unread,
    descriptions,
  ) {
    this.conversationId = conversationId;
    this.ConvType = ConvType;
    this.userIds = userIds;
    this.messages = messages || [];
    this.lastMessage = lastMessage || null;
    this.img = img || null;
    this.name = name;
    this.presense = presense || 0;
    this.unread = unread || 0;
    this.descriptions = descriptions || null;
  }
}

class User {
  constructor(
    id,
    img,
    name,
    msg,
    time,
    unread = 0,
    pinned = false,
    online = false
  ) {
    this.id = id;
    this.img = img || faker.image.avatar();
    this.name = name || faker.person.firstName();
    this.msg = msg || faker.music.songName();
    this.time = time || "08:42"; // Default value, can be dynamic if needed
    this.unread = unread;
    this.pinned = pinned;
    this.online = online;
  }

  // Example method to update user status
  setOnlineStatus(status) {
    this.presense = status;
  }

  // Example method to increment unread messages
  incrementUnread() {
    this.unread += 1;
  }
}

export { Message, Conversation, User };
