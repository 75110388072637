import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import io from "socket.io-client";
import { useDispatch } from "../../redux/store";
import { endCall } from "../../redux/slices/videoCallSlice";
import eventEmitter from "../../Emitters/eventEmitter";
import { auth } from "../../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import {SOCKET_IO_URL} from "../../constants";

const WebSocketContext = createContext(null);

export const CallWebSocketProvider = ({ children }) => {
  const socketRef = useRef(null);
  let pubPcRef = useRef(null);
  let subPcRef = useRef(null);
  const activeSubscriptions = useRef(new Map());
  const removedSubscriptions = useRef(new Map());
  const availableSubscriptions = useRef(new Map());
  const pendingOfferMap = useRef(new Map());
  const trackLabels = new Map();
  let accessToken = useRef(null);
  const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  

  const SCREEN_SHARE_VIDEO = "screen_share_video";
  const SCREEN_SHARE_AUDIO = "screen_share_audio";
  const USER_CAMERA = "user_camera";
  const USER_AUDIO = "user_audio";

  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [user, setUser] = useState(null);   
  const dispatch = useDispatch();

  const triggerUpdate = () => {
    //setUpdateTrigger(prev => prev + 1);
  };

  const getURLParameter = (name) => {
    return (
      decodeURIComponent(
        (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
          window.location.search
        ) || [, ""])[1].replace(/\+/g, "%20")
      ) || null
    );
  };

  const myRoom = useRef(
    getURLParameter("room")
      ? parseInt(getURLParameter("room"))
      : getURLParameter("room_str") || 12344
  );
  const randName = "John_Doe_" + Math.floor(10000 * Math.random());
  const myName = useRef(getURLParameter("name") || randName);
  let myFeed;

  

  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("onAuthStateChanged user = ",user);
      if (user) {
          setUser(user); // Set the authenticated user
          accessToken.current = user.stsTokenManager.accessToken
          console.log("accessToken :" ,accessToken.current);
          const socket = io(SOCKET_IO_URL ,{auth:{
            token:accessToken.current
          }});

          socketRef.current = socket;

          socket.on("connect", () => {
            console.log("socket connected");
            socket.sendBuffer = [];
          });
      
          socket.on("connect_error" , (err) => {
            console.log("connect_error : ",err);
          });
      
          socket.on("disconnect", (reason) => {
            console.log("socket disconnected", reason);
            pendingOfferMap.current.clear();
            activeSubscriptions.current.clear();
            closeAllPCs();
            dispatch(endCall());
            triggerUpdate();
          });
      
          socket.on("videoroom-error", handleVideoRoomError);
          socket.on("joined", handleJoined);
          socket.on("subscribed", handleSubscribed);
          socket.on("unsubscribed", handleUnsubscribed);
          socket.on("configured", handleConfigured);
          socket.on("feed-list", handleFeedList);
          socket.on("created", handleCreated);
          socket.on("started", handleStarted);
          socket.on("updated", handleUpdated);
          socket.on("participants-list", handleParticipantsList);
          socket.on("leaving", handleLeaving);
          socket.on("exists", handleExists);
          socket.on("rooms-list", handleRoomsList);
          socket.on("destroyed", handleDestroyed);

      } else {
          setUser(null); // No user is signed in
      }
  });

    // const getToken = async () =>{

    //   for( let i = 0 ; i < 3 ; i++){
    //    // const user = await auth.currentUser;
    //     console.log("user = ", user);
    //     if(user){
    //       accessToken.current = await user.getIdToken();
    //       break;
    //     }else{
    //       await wait(2000);
    //     }
    //   }
     
    // }
    // getToken();
   



    return () => {
      //socketRef.current.disconnect();
      unsubscribe();
    };
  });

  const joinOrCreate = ({ conversationId, display, token } = {}) => {
    const joinData = {
      conversationId,
      display,
      token,
    };
    socketRef.current.emit("joinOrCreate", {
      data: joinData,
      _access_token: "access_token",
      _id: getId(),
    });
  };

  const togglePublisherAudio = async () => {
    // Implement similar logic as in the original script
    let audioTrackPresent = false;

    const transceivers = pubPcRef.current.getTransceivers();
    transceivers.forEach((transceiver) => {
      if (transceiver.sender && transceiver.sender.track) {
        console.log("track info - ", transceiver.sender.track);

        if (transceiver.sender.track.kind === "audio") {
          audioTrackPresent = true;
          transceiver.sender.track.enabled = !transceiver.sender.track.enabled;

          console.log("track changed - ", transceiver.sender.track);

          // inform janus so that resources can be freed.
          const configureData = {
            feed: myFeed,
            streams: [
              {
                mid: transceiver.sender.mid,
                send: transceiver.sender.track.enabled,
              },
            ],
          };

          console.log("configure debug = ", configureData);

          socketRef.current.emit("configure", {
            data: configureData,
            _id: getId(),
          });
        }
      }
    });

    if (!audioTrackPresent) {
      try {
        const localStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        localStream.getTracks().forEach((track) => {
          track.onended = (evt) => {
            console.log("track.onended", evt);
            pubPcRef.current.getSenders().forEach((sender) => {
              if (sender.track === track) {
                track.stop();
                pubPcRef.current.removeTrack(sender);
              }
            });
          };
          console.log("adding track", track);
          pubPcRef.current.addTrack(track, localStream);
        });
      } catch (e) {
        console.error("Error accessing audio devices:", e);
      }
    }
  };

  const togglePublisherVideo = async () => {
    // Implement similar logic as in the original script
    let videoTrackPresent = false;

    const transceivers = pubPcRef.current.getTransceivers();
    transceivers.forEach((transceiver) => {
      if (transceiver.sender && transceiver.sender.track) {
        console.log("track info - ", transceiver.sender.track);

        if (transceiver.sender.track.kind === "video") {
          videoTrackPresent = true;
          transceiver.sender.track.enabled = !transceiver.sender.track.enabled;

          console.log("track changed - ", transceiver.sender.track);

          // inform janus so that resources can be freed.
          const configureData = {
            feed: myFeed,
            streams: [
              {
                mid: transceiver.sender.mid,
                send: transceiver.sender.track.enabled,
              },
            ],
          };

          console.log("configure debug = ", configureData);

          socketRef.current.emit("configure", {
            data: configureData,
            _id: getId(),
          });
        }
      }
    });

    if (!videoTrackPresent) {
      try {
        const localStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        localStream.getTracks().forEach((track) => {
          track.onended = (evt) => {
            console.log("track.onended", evt);
            pubPcRef.current.getSenders().forEach((sender) => {
              if (sender.track === track) {
                track.stop();
                pubPcRef.current.removeTrack(sender);
              }
            });
          };
          console.log("adding track", track);
          pubPcRef.current.addTrack(track, localStream);
        });
      } catch (e) {
        console.error("Error accessing audio devices:", e);
      }
    }
  };

  const togglePublisherAudioInput = async () => {
    // Implement similar logic as in the original script
    let audioTrackExists = false;
    const senders = pubPcRef.current.getSenders();
    senders.forEach((sender) => {
      if (
        sender.track &&
        sender.track.kind === "audio" &&
        trackLabels.get(sender.track.label) === USER_AUDIO
      ) {
        audioTrackExists = true;
        trackLabels.delete(sender.track.label);
        sender.track.stop();
        pubPcRef.current.removeTrack(sender);
      }
    });

    if (!audioTrackExists) {
      try {
        const localStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        localStream.getTracks().forEach((track) => {
          track.onended = (evt) => {
            console.log("track.onended", evt);
            pubPcRef.current.getSenders().forEach((sender) => {
              if (sender.track === track) {
                trackLabels.delete(sender.track.label);
                track.stop();
                pubPcRef.current.removeTrack(sender);
              }
            });
          };
          console.log("adding track", track);
          trackLabels.set(track.label, USER_AUDIO);
          pubPcRef.current.addTrack(track, localStream);
        });
      } catch (e) {
        console.error("Error accessing audio devices:", e);
      }
    }
  };

  const togglePublisherVideoInput = async () => {
    // Implement similar logic as in the original script
    let videoTrackExists = false;
    const transceivers = pubPcRef.current.getTransceivers();
    transceivers.forEach((transceiver) => {
      if (
        transceiver.sender &&
        transceiver.sender.track &&
        transceiver.sender.track.kind === "video" &&
        trackLabels.get(transceiver.sender.track.label) === USER_CAMERA
      ) {
        videoTrackExists = true;
        trackLabels.delete(transceiver.sender.track.label);
        transceiver.sender.track.stop();
        pubPcRef.current.removeTrack(transceiver.sender);
      }
    });

    if (!videoTrackExists) {
      try {
        const localStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        localStream.getTracks().forEach((track) => {
          track.onended = (evt) => {
            console.log("track.onended", evt);
            pubPcRef.current.getTransceivers().forEach((transceiver) => {
              if (transceiver.sender.track === track) {
                trackLabels.delete(transceiver.sender.track.label);
                track.stop();
                pubPcRef.current.removeTrack(transceiver.sender);
              }
            });
          };
          console.log("adding track", track);
          trackLabels.set(track.label, USER_CAMERA);
          pubPcRef.current.addTrack(track, localStream);
        });
      } catch (e) {
        console.error("Error accessing video devices:", e);
      }
    }
  };

  const togglePublisherScreenInput = async () => {
    // Implement similar logic as in the original script
    let screenTrackExists = false;
    const senders = pubPcRef.current.getSenders();
    senders.forEach((sender) => {
      if (
        sender.track &&
        ((sender.track.kind === "video" &&
          trackLabels.get(sender.track.label) === SCREEN_SHARE_VIDEO) ||
          (sender.track.kind === "audio" &&
            trackLabels.get(sender.track.label) === SCREEN_SHARE_AUDIO))
      ) {
        screenTrackExists = true;
        trackLabels.delete(sender.track.label);
        sender.track.stop();
        pubPcRef.current.removeTrack(sender);
      }
    });

    if (!screenTrackExists) {
      try {
        const localStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: true,
        });
        localStream.getTracks().forEach((track) => {
          track.onended = (evt) => {
            console.log("track.onended", evt);
            pubPcRef.current.getSenders().forEach((sender) => {
              if (sender.track === track) {
                trackLabels.delete(track.label);
                track.stop();
                pubPcRef.current.removeTrack(sender);
              }
            });
          };
          console.log("adding track", track);
          if (track.kind === "video") {
            trackLabels.set(track.label, SCREEN_SHARE_VIDEO);
          } else if (track.kind === "audio") {
            trackLabels.set(track.label, SCREEN_SHARE_AUDIO);
          }
          pubPcRef.current.addTrack(track, localStream);
        });
      } catch (e) {
        console.error("Error accessing video devices:", e);
      }
    }
  };

  const subscribe = ({ streams, room = myRoom.current }) => {
    const subscribeData = {
      room,
      streams,
    };
    socketRef.current.emit("subscribe", {
      data: subscribeData,
      _access_token: "access_token",
      _id: getId(),
    });
  };

  const subscribeTo = (publishers, room = myRoom.current) => {
    const newStreams = [];
    const removeStreams = [];
    publishers.forEach(({ feed, streams }) => {
      streams.forEach((s) => {
        let hasStreamInActiveSubscription = hasFeedMidActiveSubscription(
          feed,
          s.mid
        );
        if (!hasStreamInActiveSubscription && s.disabled !== true) {
          if (!streamExistsInRemovedSubscription(feed, s.description)) {
            newStreams.push({ feed, mid: s.mid });
          }
        } else if (hasStreamInActiveSubscription && s.disabled !== true) {
          // no need to add, as already present
        } else if (hasStreamInActiveSubscription && s.disabled === true) {
          // remove this stream
          removeStreams.push({ feed, mid: s.mid });
        } else {
          // no need to add as already absent and disabled
        }
      });
    });

    if (removeStreams.length > 0) {
      unsubscribe({ streams: removeStreams, room });
    } else if (newStreams.length > 0) {
      subscribe({ streams: newStreams, room });
    }
  };

  const trickle = ({ feed, candidate }) => {
    const trickleData = candidate ? { candidate } : {};
    if (feed) trickleData.feed = feed;
    const trickleEvent = candidate ? "trickle" : "trickle-complete";
    socketRef.current.emit(trickleEvent, {
      data: trickleData,
      _access_token: "access_token",
      _id: getId(),
    });
  };

  const configure = ({
    feed,
    display,
    jsep,
    restart,
    streams,
    descriptions,
  }) => {
    const configureData = {};
    if (feed) configureData.feed = feed;
    if (display) configureData.display = display;
    if (jsep) configureData.jsep = jsep;
    if (streams) configureData.streams = streams;
    if (descriptions) configureData.descriptions = descriptions;
    if (typeof restart === "boolean") configureData.restart = restart;

    const configId = getId();

    console.log("configure data - ", configureData);

    socketRef.current.emit("configure", {
      data: configureData,
      _access_token: "access_token",
      _id: configId,
    });

    if (jsep) pendingOfferMap.current.set(configId, { feed });
  };

  const publish = async ({ feed = myFeed, display = myName.current } = {}) => {
    try {
      const offer = await doOffer(feed, display);
      //configure({ feed, jsep: offer });
    } catch (e) {
      console.log("error while doing offer", e);
    }
  };

  const start = ({ jsep = null } = {}) => {
    const startData = { jsep };
    socketRef.current.emit("start", {
      _access_token: "access_token",
      data: startData,
      _id: getId(),
    });
  };

  const leave = ({ feed = myFeed } = {}) => {
    const leaveData = { feed };
    closeAllPCs();
    socketRef.current.emit("leave", {
      _access_token: "access_token",
      data: leaveData,
      _id: getId(),
    });
  };

  const unsubscribe = ({ streams, room = myRoom.current }) => {
    const unsubscribeData = { room, streams };
    socketRef.current.emit("unsubscribe", {
      _access_token: "access_token",
      data: unsubscribeData,
      _id: getId(),
    });
  };

  const exists = ({ room = myRoom.current } = {}) => {
    const existsData = { room };
    socketRef.current.emit("exists", {
      _access_token: "access_token",
      data: existsData,
      _id: getId(),
    });
  };

  const listRooms = () => {
    socketRef.current.emit("list-rooms", {
      _access_token: "access_token",
      _id: getId(),
    });
  };

  const listParticipants = ({ room = myRoom.current } = {}) => {
    const listdata = { room };
    socketRef.current.emit("list-participants", {
      _access_token: "access_token",
      data: listdata,
      _id: getId(),
    });
  };

  // Helper functions
  const getId = () => Math.floor(Number.MAX_SAFE_INTEGER * Math.random());

  const getFeedMidFromDescription = (feed_id, feed_description) => {
    for (let [_, s] of availableSubscriptions.current) {
      if (s.feed === feed_id && s.description === feed_description)
        return s.mid;
    }
    return false;
  };

  const hasFeedDescriptionInAvailableSubscription = (feed, description) => {
    for (let [_, s] of availableSubscriptions.current) {
      if (s.feed === feed && s.description === description) return true;
    }
    return false;
  };

  const addSubscription = (key) => {
    console.log(`adding subscription with key: ${key}`);

    if (
      hasFeedDescriptionInAvailableSubscription(
        removedSubscriptions.current.get(key).feed_id,
        removedSubscriptions.current.get(key).feed_description
      )
    ) {
      let addStreams = [];

      addStreams.push({
        feed: removedSubscriptions.current.get(key).feed_id,
        mid: getFeedMidFromDescription(
          removedSubscriptions.current.get(key).feed_id,
          removedSubscriptions.current.get(key).feed_description
        ),
      });
      subscribe({
        streams: addStreams,
        room: myRoom,
      });
    }

    removedSubscriptions.current.delete(key);
  };

  const removeSubscription = (key) => {
    let removeStreams = [];
    removeStreams.push({
      feed: activeSubscriptions.current.get(key).feed_id,
      mid: activeSubscriptions.current.get(key).feed_mid,
    });

    removedSubscriptions.current.set(key, activeSubscriptions.current.get(key));
    removedSubscriptions.current.get(key).feed_description_val =
      activeSubscriptions.current.get(key).feed_id +
      "|" +
      activeSubscriptions.current.get(key).feed_description;
    console.log("removed subbs - ", removedSubscriptions.current);

    if (
      hasFeedDescriptionInAvailableSubscription(
        removedSubscriptions.current.get(key).feed_id,
        removedSubscriptions.current.get(key).feed_description
      )
    ) {
      unsubscribe({
        streams: removeStreams,
        room: myRoom,
      });
    }

    activeSubscriptions.current.delete(key);
    //triggerUpdate();
    //refreshSubscriptionsUI();
    eventEmitter.emit("updateSubscriptions");
  };

  const doOffer = async (feed, display) => {
    if (!pubPcRef.current) {
      const pc = new RTCPeerConnection({
        iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
      });

      pc.onnegotiationneeded = async (event) => {
        console.log("pc.onnegotiationneeded", event);
        try {
          const offer = await pc.createOffer();
          await pc.setLocalDescription(offer);
          console.log("set local sdp OK");

          const transceivers = pc.getTransceivers();
          let descriptions = [];
          transceivers.forEach((transceiver) => {
            if (
              transceiver.sender &&
              transceiver.sender.track &&
              transceiver.sender.track.enabled === true
            ) {
              console.log("sender - ", transceiver.sender);
              descriptions.push({
                mid: transceiver.mid,
                description: trackLabels.get(transceiver.sender.track.label),
              });
            }
          });

          configure({ feed: myFeed, jsep: offer, descriptions });
        } catch (e) {
          console.log("error while doing offer", e);
          closeAllPCs();
          return;
        }
      };

      pc.onicecandidate = (event) =>
        trickle({ feed, candidate: event.candidate });
      pc.oniceconnectionstatechange = () => {
        if (
          pc.iceConnectionState === "failed" ||
          pc.iceConnectionState === "closed"
        ) {
          closePubPc();
        }
      };
      pc.ontrack = (event) => console.log("WTF pc.ontrack", event);

      pubPcRef.current = pc;

      try {
        const localStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        localStream.getTracks().forEach((track) => {
          console.log("adding track", track);
          if (track.kind === "video") {
            trackLabels.set(track.label, "user_camera");
          }
          if (track.kind === "audio") {
            trackLabels.set(track.label, "user_audio");
          }
          pc.addTrack(track, localStream);
        });
      } catch (e) {
        console.log("error while doing offer", e); // TODO: Handle no audio permission
        closeAllPCs();
        return;
      }
    } else {
      console.log("Performing ICE restart");
      pubPcRef.current.restartIce();
    }
    myFeed = feed;
  };

  const doAnswer = async (offer) => {
    if (!subPcRef.current) {
      const pc = new RTCPeerConnection({
        iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
      });

      subPcRef.current = pc;

      pc.onnegotiationneeded = (event) =>
        console.log("pc.onnegotiationneeded", event);
      pc.onicecandidate = (event) => trickle({ candidate: event.candidate });
      pc.oniceconnectionstatechange = () => {
        if (
          pc.iceConnectionState === "failed" ||
          pc.iceConnectionState === "closed"
        ) {
          closeSubPc();
        }
      };
      pc.ontrack = (event) => {
        console.log("pc.ontrack", event);

        event.track.onunmute = (evt) => console.log("track.onunmute", evt);
        event.track.onmute = (evt) => console.log("track.onmute", evt);
        event.track.onended = (evt) => console.log("track.onended", evt);

        const submid = event.transceiver?.mid || event.receiver.mid;
        const remoteStream =
          event.streams[0].id === "janus"
            ? new MediaStream([event.track])
            : event.streams[0];
        if (activeSubscriptions.current.has(submid)) {
          const stream = activeSubscriptions.current.get(submid);
          stream.ms = remoteStream;
          //refreshRemoteMediaElements();
          eventEmitter.emit("updateSubscriptions");
        }
      };
    }

    try {
      await subPcRef.current.setRemoteDescription(offer);
      console.log("set remote sdp OK");
      const answer = await subPcRef.current.createAnswer();
      await subPcRef.current.setLocalDescription(answer);
      console.log("set local sdp OK");
      eventEmitter.emit("updateSubscriptions");
      return answer;
      
    } catch (e) {
      console.log("error creating subscriber answer", e);
      closeAllPCs();
      throw e;
    }
  };

  const closePubPc = () => {
    if (pubPcRef.current) {
      console.log("closing pc for publisher");
      closePC(pubPcRef.current);
      pubPcRef.current = null;
    }
  };

  const closeSubPc = () => {
    if (subPcRef.current) {
      console.log("closing pc for subscriber");
      closePC(subPcRef.current);
      subPcRef.current = null;
    }
  };

  const closePC = (pc) => {
    if (!pc) return;
    pc.getSenders().forEach((sender) => {
      if (sender.track) sender.track.stop();
    });
    pc.getReceivers().forEach((receiver) => {
      if (receiver.track) receiver.track.stop();
    });
    pc.onnegotiationneeded = null;
    pc.onicecandidate = null;
    pc.oniceconnectionstatechange = null;
    pc.ontrack = null;
    try {
      pc.close();
    } catch (_e) {}
  };

  const closeAllPCs = () => {
    console.log("closing all pcs");
    activeSubscriptions.current.clear();
    availableSubscriptions.current.clear();
    removedSubscriptions.current.clear();
    pendingOfferMap.current.clear();
    trackLabels.clear();
    myFeed = undefined;
    refreshRemoteMediaElements();
    updateSubscriptions();
    closePubPc();
    closeSubPc();
    pubPcRef.current = undefined;
    subPcRef.current = undefined;
  };

  const hasFeedMidActiveSubscription = (feed, mid) => {
    for (let [_, s] of activeSubscriptions.current) {
      if (s.feed_id === feed && s.feed_mid === mid) return true;
    }
    return false;
  };

  const streamExistsInRemovedSubscription = (feed, description) => {
    for (let [_, s] of removedSubscriptions.current) {
      if (s.feed_description_val === feed + "|" + description) return true;
    }
    return false;
  };

  const updateAvailableSubscriptions = (publishers) => {
    publishers.forEach(({ feed, streams }) => {
      streams.forEach((s) => {
        if (s.disabled === true) {
          if (availableSubscriptions.current.has(feed + "|" + s.mid)) {
            availableSubscriptions.current.delete(feed + "|" + s.mid);
          }
        } else {
          if (!availableSubscriptions.current.has(feed + "|" + s.mid)) {
            availableSubscriptions.current.set(feed + "|" + s.mid, {
              feed: feed,
              mid: s.mid,
              description: s.description,
            });
          }
        }
      });
    });

    console.log("available subscriptions - ", availableSubscriptions.current);
  };

  const handleVideoRoomError = ({ error, _id }) => {
    console.log("videoroom error", error);
    closeAllPCs();
    if (error === "backend-failure" || error === "session-not-available") {
      return;
    }
    if (pendingOfferMap.current.has(_id)) {
      pendingOfferMap.current.delete(_id);
      return;
    }
  };

  const handleJoined = ({ data }) => {
    console.log("joined to room", data);
    try {
      publish({ feed: data.feed, display: data.display });
      updateAvailableSubscriptions(data.publishers);
      subscribeTo(data.publishers, data.room);
    } catch (e) {
      console.log("error while publishing", e);
    }
  };

  const handleSubscribed = async ({ data }) => {
    console.log("subscribed to feed", data);
    updateSubscriptions(data.streams);

    try {
      if (data.jsep) {
        const answer = await doAnswer(data.jsep);
        start({ jsep: answer });
      }
    } catch (e) {
      console.log("error while doing answer", e);
    }
  };

  const handleUnsubscribed = async ({ data }) => {
    console.log("unsubscribed to feed", data);
    updateSubscriptions(data.streams);

    try {
      if (data.jsep) {
        const answer = await doAnswer(data.jsep);
        start({ jsep: answer });
      }
    } catch (e) {
      console.log("error while doing answer", e);
    }
  };

  const handleConfigured = async ({ data, _id }) => {
    console.log("feed configured", data);
    pendingOfferMap.current.delete(_id);

    const pc = data.feed ? pubPcRef.current : subPcRef.current;
    if (data.jsep) {
      try {
        if (data.jsep.type === "offer") {
          const answer = await doAnswer(data.jsep);
          start({ jsep: answer });
        } else {
          await pc.setRemoteDescription(data.jsep);
        }
        console.log("configure remote sdp OK");
      } catch (e) {
        console.log("error setting remote sdp", e);
      }
    }
    if (data.display) {
    }

    eventEmitter.emit("updateSubscriptions");

  };

  const handleFeedList = ({ data }) => {
    console.log("new feeds available!", data);
    updateAvailableSubscriptions(data.publishers);
    subscribeTo(data.publishers, data.room);
  };

  const handleCreated = ({ data }) => {
    console.log("room created", data);
  };

  const handleStarted = ({ data }) => {
    console.log("subscriber feed started", data);
    eventEmitter.emit("updateSubscriptions");
  };

  const handleUpdated = async ({ data }) => {
    console.log("updated subscription", data);
    updateSubscriptions(data.streams);

    try {
      if (data.jsep) {
        const answer = await doAnswer(data.jsep);
        start({ jsep: answer });
      }
    } catch (e) {
      console.log("error while doing answer", e);
    }
  };

  const handleParticipantsList = ({ data }) => {
    console.log("participants list", data);
  };

  const removeAllAvailableSubscriptionsWithFeed = (feed) => {
    availableSubscriptions.current.forEach((value, key) => {
      if (value.feed === feed) {
        availableSubscriptions.current.delete(key);
      }
    });

    console.log("available subscriptions - ", availableSubscriptions.current);
  };

  const handleLeaving = ({ data }) => {
    console.log("feed leaving", data);
    if (data.feed) {
      if (data.feed === myFeed) {
        closeAllPCs();
      } else {
        removeAllAvailableSubscriptionsWithFeed(data.feed);
        const streams = Array.from(activeSubscriptions.current.values()).map(
          (s) => {
            const stream = { ...s };
            if (stream.feed_id === data.feed) {
              stream.active = false;
              stream.feed_id = null;
              stream.feed_mid = null;
              stream.feed_display = null;
            }
            return stream;
          }
        );
        updateSubscriptions(streams);
      }
    }
  };

  const handleExists = ({ data }) => {
    console.log("room exists", data);
  };

  const handleRoomsList = ({ data }) => {
    console.log("rooms list", data);
  };

  const handleDestroyed = ({ data }) => {
    console.log("room destroyed", data);
    if (data.room === myRoom.current) {
    }
  };

  const updateSubscriptions = (streams) => {
    console.log("updateSubscriptions : streams = ", streams);
    if (!streams) return;
    const newSubscriptions = new Map();
    streams.forEach((s) => {
      if (s.active) {
        s.ms = activeSubscriptions.current.get(s.mid)?.ms;
        newSubscriptions.set(s.mid, s);
      }
    });
    activeSubscriptions.current = newSubscriptions;
    console.log("updateSubscriptions : newSubscriptions = ", newSubscriptions);
    refreshRemoteMediaElements();
    //triggerUpdate();
    eventEmitter.emit("updateSubscriptions");
  };

  const refreshRemoteMediaElements = () => {
    for (let [sub_mid, s] of activeSubscriptions.current) {
      const { feed_display, type, feed_id, feed_mid, active, ms } = s;
      if (active) {
        //   if (type === "video")
        //     setRemoteVideoElement(
        //       ms,
        //       sub_mid,
        //       [feed_display, feed_id, feed_mid, sub_mid].join("|")
        //     );
        //   if (type === "audio") setRemoteAudioElement(ms, sub_mid);
      }

      // dispatch(updateStreams({sub_mid:sub_mid, stream : s}));
    }
  };

  const contextValue = {
    joinOrCreate,
    togglePublisherAudio,
    togglePublisherVideo,
    togglePublisherAudioInput,
    togglePublisherVideoInput,
    togglePublisherScreenInput,
    subscribe,
    subscribeTo,
    trickle,
    configure,
    publish,
    start,
    leave,
    unsubscribe,
    exists,
    listRooms,
    listParticipants,
    triggerUpdate,
    removeSubscription,
    addSubscription,
    activeSubscriptions,
    removedSubscriptions,
    availableSubscriptions,
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

export default CallWebSocketProvider;
