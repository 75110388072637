// src/store/actions/authActions.js
import {signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../firebase/firebase";

export const SET_USER = "SET_USER";
export const LOGOUT = "LOGOUT";
export const UPDATE_DETAILS = "UPDATE_DETAILS";

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const logout = () => ({
  type: LOGOUT,
});

export const updateDetails = ({name, username,img}) => (
  {
    type: UPDATE_DETAILS,
    payload : {name,username,img},
  }
);

export const registerWithEmail = (userCredential) => async (dispatch) => {
  try {
    
    dispatch(setUser(userCredential.user));
  } catch (error) {
    console.error("Error registering with email:", error);
  }
};

export const loginWithEmail = (userCredential) => async (dispatch) => {
  try {
    dispatch(setUser(userCredential.user));
  } catch (error) {
    console.error("Error logging in with email:", error);
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    dispatch(logout());
  } catch (error) {
    console.error("Error logging out:", error);
  }
};

export const updateUserDetails = ({name, username, img}) => async (dispatch) => {
  try {
    dispatch(updateDetails({name, username,img}));
  } catch (error) {
    console.error("Error logging out:", error);
  }
};

export const checkAuthState = () => (dispatch) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      dispatch(setUser(user));
    } else {
      dispatch(logout());
    }
  });
};
