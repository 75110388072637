// src/ConnectionStatusBar.js
import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import eventEmitter from '../Emitters/eventEmitter';
import { webSocketService } from '../webSocket/websocket';
import { useDispatch, useSelector } from '../redux/store';
import { logout, logoutUser } from '../redux/slices/auth/authActions';
import { getAuth, signOut } from "firebase/auth";

const ConnectionStatusBar = () => {
  const [isConnected, setIsConnected] = useState(true);
  const dispatch = useDispatch();
  const user = useSelector( (state) => state.auth.user )
  const auth = getAuth();

  useEffect(() => {
    const handleWsClose = () => {
        console.log("ConnectionStatusBar handleWsClose");
        if(user){
          setIsConnected(false);
        }
        
    }
    const handleWsOpen = () => {
        console.log("ConnectionStatusBar handleWsOpen");
        setIsConnected(true);
    }
    const handleNoUser = () => {
      console.log("wsNoUser");
      signOut(auth).then(() => {
        console.log("User signed out successfully.");
        // Redirect to login page
        window.location.href = "/login";
      }).catch((error) => {
        console.error("Error signing out:", error);
      });
      dispatch(logoutUser());
      dispatch(logout());
    }

    eventEmitter.on('wsClose', handleWsClose);
    eventEmitter.on('wsOpen', handleWsOpen);
    eventEmitter.on('wsNoUser' , handleNoUser);

    return () => {
      eventEmitter.off('wsClose', handleWsClose);
      eventEmitter.off('wsOpen', handleWsOpen);
    };
  }, []);

  const handleReconnect = async () => {
    try {
        const result = await webSocketService.prepare();
        webSocketService.connect(result["ticket"]);
    } catch (error) {
      console.error('Failed to reconnect:', error);
    }
  };

  if (isConnected) return null;

  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        bgcolor: 'red',
        color: 'white',
        p: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <Typography variant="body1">Disconnected</Typography>
      <Button variant="contained" color="primary" onClick={handleReconnect}>
        Reconnect
      </Button>
    </Box>
  );
};

export default ConnectionStatusBar;
