// src/utils/api.js
import { auth } from "../firebase/firebase";
import { BASE_API_URL, BASE_MEDIA_API_URL } from "../constants";

const BASE_URL = BASE_API_URL;
const BASE_MEDIA_URL = BASE_MEDIA_API_URL;

export const makeApiRequest = async (path, method = "GET", data = null) => {
  const url = `${BASE_URL}${path}`;
  const maxRetry = 2;
  const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    var user = null;
    var token = null;
    for( let i = 0 ; i < maxRetry ; i++){
      user = await auth.currentUser;
      if(user){
        token = await user.getIdToken();
        break;
      }else{
        await wait(2000);
      }
    }
    if(!user){
      
      throw new Error(`Error: failed to get user`);
    }else if ( !token){
      throw new Error(`Error: failed to get user toekn`);
    }
    
    //console.log( "makeApiReques token =  " + token);
    
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(token && { 'Authorization': `Bearer ${token}` }),
      },
    };
    console.log("api input path = ", url);
    console.log("api input data for " + path + "  = ", data);

    if (method === "POST" && data) {
      options.body = JSON.stringify(data);
    }
    var response = null;
    //for ( let i = 0 ;i < maxRetry ;i++ ){
      response = await fetch(url, options);
      //if (response.ok) { break ;}
    //}
    console.log("api response " + path + " = " , response);
    const result = await response.json();
    console.log("api result for " + path + " = " , result);
    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    
    
    
    return result;
  
};

export const makeMediaRequest = async (path, method = "GET", data = null) => {
  const url = `${BASE_MEDIA_URL}${path}`;
  const maxRetry = 2;
  const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    var user = null;
    var token = null;
    for( let i = 0 ; i < maxRetry ; i++){
      user = await auth.currentUser;
      if(user){
        token = await user.getIdToken();
        break;
      }else{
        await wait(2000);
      }
    }
    if(!user){
      throw new Error(`Error: failed to get user`);
    }else if ( !token){
      throw new Error(`Error: failed to get user toekn`);
    }
    
    //console.log( "makeApiRequest user =" + user + " token =  " + token);
    
    const options = {
      method,
      headers: {
        ...(token && { 'Authorization': `Bearer ${token}` }),
      },
    };
    console.log("media api input path = ", url);
    console.log("media api input data = ", data);

    if (method === "POST" && data) {
      options.body = JSON.stringify(data);
    }
    if(method === "PUT" && data){
      options.body = data;
    }
    var response = null;
    //for ( let i = 0 ;i < maxRetry ;i++ ){
      response = await fetch(url, options);
      //if (response.ok) { break ;}
    //}
    console.log("media api response = " , response);
    const result = await response.json();
    console.log("media api result = " , result);
    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return result;
  
};

// Usage examples:
// const data = await makeApiRequest('/users', 'GET');
// const response = await makeApiRequest('/users/create', 'POST', { key: 'value' });
