import {
  Avatar,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import logo from "../../assets/Images/logo.png";
import { Nav_Buttons } from "../../data";
import { Gear } from "phosphor-react";
import { faker } from "@faker-js/faker";
import AntSwitch from "../../components/AntSwitch";
import useSettings from "../../hooks/useSettings";
import LogoutButton from "../../components/logoutButton";

import { useNavigate } from "react-router-dom";
import { useSelector } from "../../redux/store";
import ProfileView from "../../components/ProfileView";
import { useSearchParams } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";

const DashboardLayout = () => {
  const theme = useTheme();
  const isMobile = useResponsive("between", "md", "xs", "sm");
  const [selected, setSelected] = useState(4);
  const { onToggleMode } = useSettings();
  const navigate = useNavigate();

  const name = useSelector((state) => state.auth.name);
  const username = useSelector((state) => state.auth.userName);
  const image_url = useSelector((state) => state.auth.imgUrl);
  const [searchParams] = useSearchParams();

  const [showProfile, setShowProfile] = useState(false);

  const toggleProfile = () => {
    setShowProfile(!showProfile);
  };

  return (
    <>
      <Stack direction={"row"}>
        <Box
          p={2}
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: "0 0px 2px rgba(0, 0, 0, 0.25)",
            height: "100vh",
            width: 150,
          }}
        >
          <Stack
            direction={"column"}
            justifyContent={"space-between"}
            alignItems={"start"}
            sx={{ height: "100%" }}
            spacing={3}
          >
            <Stack alignItems={"center"} spacing={4}>
              <Box
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  height: 64,
                  width: 64,
                  borderRadius: 1.5,
                }}
              >
                <img src={logo} alt={"chat-app-logo"} />
              </Box>

              <Stack
                spacing={2}
                width={"max-content"}
                direction={"column"}
                alignItems={"center"}
              >
                {Nav_Buttons.map((el) =>
                  el.index === selected ? (
                    <Box
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 1.5,
                      }}
                      key={el.index}
                    >
                      <IconButton
                        sx={{ width: "max-content", color: "#fff" }}
                        key={el.index}
                      >
                        {el.icon}
                      </IconButton>
                    </Box>
                  ) : (
                    <IconButton
                      sx={{
                        width: "max-content",
                        color:
                          theme.palette.mode === "light"
                            ? "#000"
                            : theme.palette.text.primary,
                      }}
                      key={el.index}
                      onClick={() => {
                        setSelected(el.index);
                        if (el.index === 0) {
                          navigate("/app");
                        } else if (el.index === 1) {
                          navigate("/groupChats");
                        } else if (el.index === 3) {
                          navigate("/searchUsers");
                        } else if(el.index === 4){
                          navigate("/homePage");
                        } else if(el.index === 5){
                          navigate("/notifications")
                        }
                      }}
                    >
                      {el.icon}
                    </IconButton>
                  )
                )}
                {/* <Divider sx={{ width: "48px" }} />
                {selected === 6 ? (
                  <Box
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 1.5,
                    }}
                  >
                    <IconButton sx={{ width: "max-content", color: "#fff" }}>
                      <Gear></Gear>
                    </IconButton>
                  </Box>
                ) : (
                  <IconButton
                    onClick={() => {
                      setSelected(6);
                    }}
                  >
                    <Gear></Gear>
                  </IconButton>
                )} */}
              </Stack>
            </Stack>

            <Stack spacing={4} alignItems={"start"}>
              <Stack direction={"row"} padding={1} alignItems={"center"}>
              <Typography>Logout</Typography>
              <LogoutButton />
              
              </Stack>
              
              {/* <AntSwitch
                onChange={() => {
                  onToggleMode();
                }}
                defaultChecked
              /> */}
              <Stack alignItems={"flex-start"} direction={"column"}>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <Box
                    style={{
                      zIndex: 10,
                      position: "fixed",
                      display: showProfile ? "inline" : "none",
                      bottom: 61,
                      left:60
                    }}
                  >
                    <ProfileView onClose={toggleProfile} />
                  </Box>

                  <Avatar
                    src={image_url + "/img_medium.webp"}
                    sx={{ width: 42, height: 42 }}
                    onClick={toggleProfile}
                  />

                  <Stack
                    direction={"column"}
                    alignItems={"flex-start"}
                    spacing={0.25}
                  >
                    <Typography variant="subtitle2">{name}</Typography>
                    <Typography variant="caption">{username}</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Outlet />
      </Stack>
    </>
  );
};

export default DashboardLayout;
