import React ,{useState} from 'react';
import { Box, Avatar, Typography, Divider, Button } from '@mui/material';
import { green } from '@mui/material/colors';
import { CopyAll, AccountCircle, Person } from '@mui/icons-material';
import StatusDialog from './StatusSelector';
import { useSelector } from '../redux/store';
import { webSocketService } from '../webSocket/websocket';
import { useNavigate } from 'react-router-dom';

const ProfileView = ({ onClose }) => {
    const user = useSelector( (state) => state.auth.user)
    const userImg = useSelector((state) => state.auth.imgUrl);
    const name = useSelector((state) => state.auth.name)
    const username =  useSelector((state) => state.auth.userName)
    const [openStatusDialog, setOpenStatusDialog] = useState(false);
    const navigate = useNavigate();

  const handleStatusDialogClose = (status) => {
    console.log('Selected Status:', status);
    setOpenStatusDialog(false);

    var presence = 0;
    switch (status) {
        case "Online":
            presence = 2;
          break;
        case "Idle":
            presence = 3;
          break;
        case "Do Not Disturb":
            presence = 7;
          break;
        case "Invisible":
            presence = 1;
          break;
        default:
          break;
      }

    webSocketService.sendPresenceUpdate({userId:user.uid, presence: presence});

  };

  return (
    <Box
      sx={{
        width: 300,
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 3,
        p: 2,

      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Avatar
          src={userImg+"/img_medium.webp"}
          alt="Profile"
          sx={{ width: 56, height: 56, mr: 2 }}
        />
        <Box
          sx={{
            width: 14,
            height: 14,
            bgcolor: green[500],
            borderRadius: '50%',
            position: 'relative',
            top: 24,
            left: -18,
            border: '2px solid white',
          }}
        />
      </Box>
      <Box sx={{ textAlign: 'left', mb: 2 }}>
        <Typography variant="h6">{name}</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {username}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ textAlign: 'left', mt: 2 }}>
        <Typography variant="body2" color="text.secondary">
          ABOUT ME
        </Typography>
      </Box>
      <Divider sx={{ mt: 2 }} />
      <Box sx={{ textAlign: 'left', mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Box
            sx={{
              width: 10,
              height: 10,
              bgcolor: green[500],
              borderRadius: '50%',
              mr: 1,
            }}
          />
          <Typography variant="body2">Online</Typography>
        </Box>
        {/* <Button
          variant="contained"
          color="secondary"
          startIcon={<Person />}
          sx={{ mb: 1, width: '100%' }}
          onClick={() => setOpenStatusDialog(true)}
        >
          Set Custom Status
        </Button> */}
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Person />}
          sx={{ mb: 1, width: '100%' }}
          onClick={() => {
            navigate("/profilePage")
            onClose();
          } }
        >
          View Full Profile
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={onClose}
          sx={{ mt: 1, width: '100%' }}
        >
          Close
        </Button>
      </Box>
      <StatusDialog open={openStatusDialog} onClose={handleStatusDialogClose} />
    </Box>
  );
};

export default ProfileView;
