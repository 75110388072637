import React from 'react';
import { Dialog, DialogTitle, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { green, yellow, red, grey } from '@mui/material/colors';
import { Circle } from '@mui/icons-material';
import { useSelector } from 'react-redux';

const StatusDialog = ({ open, onClose }) => {

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Set Custom Status</DialogTitle>
      <List>
        <ListItem button onClick={() => onClose('Online')}>
          <ListItemIcon>
            <Circle sx={{ color: green[500] }} />
          </ListItemIcon>
          <ListItemText primary="Online" />
        </ListItem>
        <ListItem button onClick={() => onClose('Idle')}>
          <ListItemIcon>
            <Circle sx={{ color: yellow[500] }} />
          </ListItemIcon>
          <ListItemText primary="Idle" />
        </ListItem>
        <ListItem button onClick={() => onClose('Do Not Disturb')}>
          <ListItemIcon>
            <Circle sx={{ color: red[500] }} />
          </ListItemIcon>
          <ListItemText primary="Do Not Disturb" />
        </ListItem>
        <ListItem button onClick={() => onClose('Invisible')}>
          <ListItemIcon>
            <Circle sx={{ color: grey[500] }} />
          </ListItemIcon>
          <ListItemText primary="Invisible" />
        </ListItem>
      </List>
    </Dialog>
  );
};

export default StatusDialog;
