import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import sessionStorage from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import appReducer from "./slices/app";
import authReducer from "./slices/auth/authReducer";
import chatsReducer from "./slices/chats";
import snackbarReducer from "./slices/snackbarSlice"
import usersReducer from "./slices/userSlice"
import eventsReducer from "./slices/eventsSlice"
import notificationsReducer from "./slices/notificationsSlice"
import videoCallReducer from "./slices/videoCallSlice"


//slices


const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  //whitelist: [],
  //blacklist: [],
};

// Session Persist config for user cache
const usersPersistConfig = {
  key: 'users',
  storage: sessionStorage,
  keyPrefix: 'redux-',
};

const mainReducer  = combineReducers({
  app: appReducer,
  auth: authReducer,
  chats:chatsReducer,
  users: persistReducer(usersPersistConfig, usersReducer),
  snackbar: snackbarReducer,
  events: eventsReducer,
  notifications: notificationsReducer,
  videoCall: videoCallReducer,

});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
      state = undefined; // Reset the state to initial state
  }
  return mainReducer(state, action);
};


export { rootPersistConfig, rootReducer };
