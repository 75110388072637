import  { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addMessage, addConversation } from "../redux/slices/chats";
import { EventTypes } from "./websocket";
import { Message } from "../data/model";
import { webSocketService } from "./websocket";
import { useSelector } from "../redux/store";
import { Conversation } from "../data/model";
import { getCurrentTimeInNanoseconds } from "../utils/time";
import { makeApiRequest } from "../api/api";
import { showSnackbar } from "../redux/slices/snackbarSlice";
import { addNotification } from "../redux/slices/notificationsSlice";

const WebSocketHandlerComponent = () => {
  const dispatch = useDispatch();
  const conversations = useSelector((state) => state.chats.conversations);

  const handleIncomingMessage = async ({ data }) => {
    try {
      const conversationId = data["conversation_id"];
      const msgConversation = conversations[conversationId];

      console.log(data);

      const msg = new Message(
        data["id"],
        data["sender_id"],
        data["receiver_id"],
        data["message_body"],
        data["sent_at"],
        new Date(),
        false,
        "msg",
        false,
        data["message_type"],
        data["metadata"]
      );

      if (!msgConversation) {
        //Conversation(conversationId: any, ConvType: any, userIds: any, messages: any, lastMessage: any, img: any, name: any, online: any, unread: any)
        var response = null;
        var is_group = "Direct";

        const payload = {
          user_id: data["receiver_id"],
          conversation_id: conversationId,
        };
        response = await makeApiRequest(
          "/chat/getConversationWithId",
          "POST",
          payload
        );
        if (response["conv_type"] === "Group") {
          is_group = "Group";
        } else {
          response = await makeApiRequest("/users/get?id=" + data["sender_id"]);
        }

        const newConversation = new Conversation(
          conversationId,
          is_group,
          [msg.senderId , msg.receiverId],
          [],
          null,
          response["image_url"], // from fetch user details
          response["name"], // from fetch user details
          true,
          null
        );

        dispatch(
          addConversation({
            conversationId: conversationId,
            conversation: newConversation,
          })
        );
      }

      if (data["sender_id"] !== data["receiver_id"]) {
        dispatch(
          addMessage({
            conversationId: conversationId,
            message: msg,
          })
        );

        const msgDeliveredAck = {
          message_id: data["id"],
          sender_id: data["sender_id"],
          time: getCurrentTimeInNanoseconds(),
        };
        const ackData = {
          type: EventTypes.sendMessageDeliveredAck, //
          id: data["id"],
          payload: msgDeliveredAck,
        };

        webSocketService.sendMessageDeliveredAck(ackData);
      }
    } catch (error) {
      console.log("Incomming message conversations error ", error);
      dispatch(
        showSnackbar({ message: "Failed to Receive an Incomming message" })
      );
    }
  };

  const handleIncomingNotification = ({ notificationType, data }) => {
    console.log(
      "handleIncomingNotification with type = " +
        notificationType +
        " and data = ",
        data
    );

    //const data = fulldata["metadata"];

    if (notificationType === EventTypes.EventNewCalendarInvite) {
      dispatch(
        addNotification({
          type: notificationType,
          id: data["event_id"],
          data: data,
          time: data["created_at"],
        })
      );
    } else if (
      notificationType === EventTypes.EventNewUserAddedInCalendarEvent
    ) {
      dispatch(
        addNotification({
          type: notificationType,
          id: data["id"],
          data: data,
          time: data["created_at"],
        })
      );
    } else if (notificationType === EventTypes.EventNewCalendarRequest) {
      dispatch(
        addNotification({
          type: notificationType,
          id: data["event_id"],
          data: data,
          time: data["created_at"],
        })
      );
    } else if(notificationType === EventTypes.EventCalendarRequestStatusChange){
      dispatch(
        addNotification({
          type: notificationType,
          id: data["event_id"],
          data: data,
          time: data["created_at"],
        })
      );
    }else if(notificationType === EventTypes.EventNewSocialRequest){
      dispatch(
        addNotification({
          type: notificationType,
          id: data["id"],
          data: data,
          time: data["created_at"],
        })
      );
    } else if(notificationType === EventTypes.EventSocialRequestStatusChange){
      dispatch(
        addNotification({
          type: notificationType,
          id: data["id"],
          data: data,
          time: data["created_at"],
        })
      );
    } else if(notificationType === EventTypes.EventPostCreatedSuccesfully){
      data.post_status = "SUCCESS";
      dispatch(
        addNotification({
          type: notificationType,
          id: data["post_id"],
          data: data,
          time: data["created_at"],
        })
      );
    } else if(notificationType === EventTypes.EventPostCreationFailed){
      data.post_status = "FAILURE";
      dispatch(
        addNotification({
          type: notificationType,
          id: data["created_at"],
          data: data,
          time: data["created_at"],
        })
      );
    }
  };

  useEffect(() => {
    webSocketService.registerEventHandler(
      EventTypes.EventIncomingChatMessage,
      handleIncomingMessage
    );
    webSocketService.registerEventHandler(
      EventTypes.EventIncomingNotification,
      handleIncomingNotification
    );
    console.log("WebSocket handler registered");
  });

  return null; // This component does not render any UI
};

export default WebSocketHandlerComponent;
