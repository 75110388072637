// src/websocket.js
import { WsUrl } from "../constants";
import { makeApiRequest } from "../api/api";
import Cookies from "js-cookie";
import eventEmitter from "../Emitters/eventEmitter";

class WebSocketService {
  constructor() {
    this.socket = null;
    this.eventHandlers = {}; // Stores event handlers for different message types
    this.maxRetries = 3; // Maximum number of retry attempts
    this.retryDelay = 2000; // Delay between retries in milliseconds
    this.retryCount = 0; // Current retry count
    this.ackCallbacks = {}; // Stores callbacks for ack messages
  }

  async prepare() {
    try {
      const response = await makeApiRequest(
        "/ws/ticketing/createTicket",
        "GET"
      );
      console.log(response);
      return response;
    } catch (error) {
      console.log("emit wsNoUser");
      eventEmitter.emit('wsNoUser');
      console.log(error);
    }
  }

  connect(ticket) {
    console.log("ws connecting");
   // if (!this.socket) {
      this.socket = new WebSocket(WsUrl + "?ticket=" + ticket);

      this.socket.onopen = () => {
        console.log("WebSocket connection established");
        setWebSocketTicket(ticket);
        eventEmitter.emit('wsOpen'); 
      };

      this.socket.onclose = async () => {
        console.log("WebSocket connection closed");
        eventEmitter.emit('wsClose');
      };

      this.socket.onerror = async (error) => {
        console.error("WebSocket error:", error);
      };

      this.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);

        const { type, id, payload } = data;
        console.log("tilak event type = ", type);
        console.log("payload = ", payload);
        if (type === EventTypes.AckEvent && this.ackCallbacks[id]) {
          console.log("firing callback for id = ", id);
          this.ackCallbacks[id](payload);
          delete this.ackCallbacks[id];
        } else if (
          type >= EventTypes.EventNewUserAddedInCalendarEvent &&
          type <= EventTypes.EventPostCreationFailed &&
          this.eventHandlers[EventTypes.EventIncomingNotification]
        ) {
          console.log("tilak event type = " + type + " handler exists");
          this.eventHandlers[EventTypes.EventIncomingNotification]({
            notificationType: type,
            data: payload["metadata"],
          });
        } else if (this.eventHandlers[type]) {
          console.log("tilak event type = " + type + " handler exists");
          this.eventHandlers[type]({ data: payload });
        } else {
          console.warn(`No handler for message type: ${type}`);
        }
      };
    //}
  }

  sendMessage(message, callback) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      console.log("register call back for msg id = ", message.id);
      this.ackCallbacks[message.id] = callback;
      this.socket.send(JSON.stringify(message));
    } else {
      console.error("WebSocket connection is not open");
    }
  }

  sendMessageDeliveredAck(msgDeliveredAck) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(msgDeliveredAck));
    } else {
      console.error("WebSocket connection is not open");
    }
  }

  sendPresenceSubscription({ senderId, targetId, subscribe }) {
    const payload = {
      sender_id: senderId,
      target_id: targetId,
      unsubscribe: subscribe,
    };

    const data = {
      type: EventTypes.EventOutgoingPresenceSubscriptionEvent,
      payload: payload,
    };

    console.log("send websocket event sendPresenceSubscription data = ", data);
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(data));
    } else {
      console.error("WebSocket connection is not open");
    }
  }

  sendPresenceUpdate({ userId, presence }) {
    const payload = {
      user_id: userId,
      status: presence,
    };

    const data = {
      type: EventTypes.EventOutgoingUserPresenceChange,
      payload: payload,
    };

    console.log("send websocket event sendPresenceUpdate data = ", data);
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(data));
    } else {
      console.error("WebSocket connection is not open");
    }
  }

  registerEventHandler(type, handler) {
    this.eventHandlers[type] = handler;
  }

  removeEventHandler(type) {
    delete this.eventHandlers[type];
  }

  close() {
    if (this.socket) {
      this.socket.close();
      console.log("websocket connection closed");
    }
  }
}

const setWebSocketTicket = (ticket) => {
  Cookies.set("websocketTicket", ticket, { secure: true, sameSite: "Strict" });
};

const getWebSocketTicket = () => {
  return Cookies.get("websocketTicket");
};

const removeWebSocketTicket = () => {
  Cookies.remove("websocketTicket");
};

const EventTypes = Object.freeze({
  Unknown: 0,

  /*
		fired back to client for every event type received, payload's event type will signify the type of
		event it acknowledges along with an ID to keep track on client side which event has been received
	*/
  AckEvent: 1,

  /*
		events related to incoming messages, their delivery and seen status, note ack will be fired for
		all the events
	*/

  EventOutgoingChatMessage: 2,
  EventOutgoingDeliveredUpdate: 3,
  EventOutgoingReadUpdate: 4,

  EventIncomingChatMessage: 5,
  EventIncomingReadUpdate: 6,
  EventIncomingDeliveredUpdate: 7,

  /*
		events related to exchanging SDP messages for webRTC communication
	*/
  // EventOutgoingSDPOffer
  EventOutgoingSDPAnswer: 8,

  EventIncomingSDPOffer: 9,
  // EventIncomingSDPAnswer

  //events related to Calendar events modifications,
  EventIncomingNotification: 100,
  //notification sub types
  EventNewUserAddedInCalendarEvent: 13,
  EventUserRemovedFromCalendarEvent: 14,
  EventCalendarEventUpdated: 15,
  EventCalendarEventDeleted: 16,
  EventNewCalendarRequest: 17,
  EventCalendarRequestStatusChange: 18,
  EventNewCalendarInvite: 19,
  EventCalendarInviteStatusChange: 20,


  EventNewSocialRequest: 21,
	EventSocialRequestStatusChange : 22,
  /*
		user presence status related events
	*/
  EventOutgoingUserPresenceChange: 23,
  EventOutgoingPresenceSubscriptionEvent: 24,

  EventNotifySubscriberPresenceChange: 25,
  EventPostCreatedSuccesfully : 29,
  EventPostCreationFailed : 30,
});

const webSocketService = new WebSocketService();
export {
  webSocketService,
  EventTypes,
  getWebSocketTicket,
  removeWebSocketTicket,
};
