// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Replace with your own Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyDZ0CerQdGwsSED-pHoOTlKcCmXtiHScKI",
  authDomain: "test-client-5a759.firebaseapp.com",
  projectId: "test-client-5a759",
  storageBucket: "test-client-5a759.appspot.com",
  messagingSenderId: "799323771",
  appId: "1:799323771:web:e737f677bc53556ac0d4de",
  measurementId: "G-HXG8VVQ1B4",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { auth ,analytics };
