// videoCallSlice.js
import { createSlice } from "@reduxjs/toolkit";

const videoCallSlice = createSlice({
  name: "videoCall",
  initialState: {
    isCallActive: false,
    conversationId: null,
    participants: [],
    streams: {},
    activeSubscriptions: new Map(),
    removedSubscriptions: new Map(),
    availableSubscriptions: new Map(),
  },
  reducers: {
    startCall: (state, action) => {
      state.isCallActive = true;
      state.conversationId = action.payload.conversationId;
      ///state.stream = action.payload.stream;
    },
    endCall: (state) => {
      state.isCallActive = false;
      state.participants = [];
      state.stream = null;
      state.conversationId = null;
    },

    updateStreams: (state,action) => {

      // const {sub_mid, stream } = action.payload;
      // console.log(sub_mid);
      // console.log("stream = ",stream);
      // if (!state.streams) {
      //   state.streams = {}; // Ensure streams is an object
      // }
      // state.streams[sub_mid] = stream;

    },


    addParticipant: (state, action) => {
      state.participants.push(action.payload);
    },
    removeParticipant: (state, action) => {
      state.participants = state.participants.filter(
        (participant) => participant.id !== action.payload.id
      );
    },

    updateActiveSubscriptions: (state, action) => {
      console.log("updateActiveSubscriptions payload = ",action.payload);
      state.activeSubscriptions = (action.payload);
    },
    updateRemovedSubscriptions: (state, action) => {
      console.log("updateRemovedSubscriptions payload = ",action.payload);
      state.removedSubscriptions = (action.payload);
    },
    updateAvailableSubscriptions: (state, action) => {
      console.log("updateAvailableSubscriptions payload = ",action.payload);
      state.availableSubscriptions = (action.payload);
    },
  },
});

export const {
  startCall,
  endCall,
  addParticipant,
  removeParticipant,
  updateActiveSubscriptions,
  updateRemovedSubscriptions,
  updateAvailableSubscriptions,
  updateStreams,
} = videoCallSlice.actions;

export default videoCallSlice.reducer;
