import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  conversations: {},
  sortedConversationIds: [], // Array to maintain sorted order of conversation IDs
  isSelected: false,
  selectedChatId: null,
  selectedGroupConversationId : null,
  scroll: false,
};

const slice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    //update isSeleted
    updateSelected(state, action) {
      state.isSelected = action.payload.isSelected;
      state.selectedChatId = action.payload.chatId;
      state.scroll = !state.scroll;
    },
    updateGroupSelected(state, action){
      state.selectedGroupConversationId = action.payload.GroupchatId;
    },
    needScroll(state, action) {
      state.scroll = !state.scroll;
    },
    addConversation(state, action) {
      const { conversationId, conversation } = action.payload;
      if (!state.conversations) {
        state.conversations = {};
      }
      if (!state.sortedConversationIds) {
        state.sortedConversationIds = [];
      }
      state.conversations[conversationId] = conversation;
      const isPresent = state.sortedConversationIds.includes(conversationId);
      if(!isPresent){
        state.sortedConversationIds.push(conversationId);
      }
      
      // Ensure the array is sorted after adding a new conversation
      state.sortedConversationIds.sort((a, b) => {
        const timeA = state.conversations[a]?.lastMessage?.sentAt || 0;
        const timeB = state.conversations[b]?.lastMessage?.sentAt || 0;
        return timeB - timeA;
      });

      state.scroll = !state.scroll;

    },

    addMessage(state, action) {
      const { conversationId, message  } = action.payload;
      const conversation = state.conversations[conversationId];
      console.log("tilak adding message");
  
      state.conversations[conversationId] = {
        ...conversation,
        messages: [...conversation.messages, message],
      };
      const freshConversation = state.conversations[conversationId];
      freshConversation.lastMessage = message;
      //console.log('State updated with new message', state.conversations[conversationId].messages);

        // Ensure the array is sorted after adding a new message
        state.sortedConversationIds = state.sortedConversationIds.filter(id => id !== conversationId);
        state.sortedConversationIds.unshift(conversationId);
        state.sortedConversationIds.sort((a, b) => {
          const timeA = state.conversations[a]?.lastMessage?.sentAt || 0;
          const timeB = state.conversations[b]?.lastMessage?.sentAt || 0;
          return timeB - timeA;
        });

        state.scroll = !state.scroll;
    
    },

    addMessagesByList(state, action) {
      const { conversationId, messages } = action.payload;
      const conversation = state.conversations[conversationId];
      const newMessages = [...messages,...conversation.messages];
      const lastMessage = newMessages[newMessages.length - 1];

      state.conversations = {
        ...state.conversations,
        [conversationId]: {
          ...conversation,
          messages: newMessages,
          lastMessage: lastMessage
        }
      };

      state.sortedConversationIds = [conversationId, ...state.sortedConversationIds.filter(id => id !== conversationId)].sort((a, b) => {
        const timeA = state.conversations[a]?.lastMessage?.sentAt || 0;
        const timeB = state.conversations[b]?.lastMessage?.sentAt || 0;
        return timeB - timeA;
      });
    },
    

    addUserToConversation(state, action) {
      const { conversationId, userIdList } = action.payload;
      const conversation = state.conversations[conversationId];
      for (const userId of userIdList) {
        console.log(userId);
        if (conversation && (conversation.ConvType === "Group" || conversation.ConvType === "Event" )) {
          if (!conversation.userIds.includes(userId)) {
            conversation.userIds.push(userId);
          }
        }
      }
      state.scroll = !state.scroll;
    },

    deleteConversation(state, action) {
      const { chatId } = action.payload;

      console.log("deleting conversation with id = " ,chatId);

      // Remove the conversation from the conversations object
      const { [chatId]: deletedChat, ...newConversations } = state.conversations;

      // Create a new array for sortedConversationIds excluding the deleted chatId
      const newSortedConversationIds = state.sortedConversationIds.filter(id => id !== chatId);

      // Determine if the selected chat is the one being deleted
      const isSelectedChatDeleted = state.selectedChatId === chatId;
      const isSelectedGroupConversationDeleted = state.selectedGroupConversationId === chatId;

      return {
        ...state,
        conversations: newConversations,
        sortedConversationIds: newSortedConversationIds,
        isSelected: isSelectedChatDeleted ? false : state.isSelected,
        selectedChatId: isSelectedChatDeleted ? null : state.selectedChatId,
        selectedGroupConversationId: isSelectedGroupConversationDeleted ? null : state.selectedGroupConversationId,
      };
    }



  },
});

//reducers
export default slice.reducer;
export const { updateSelected, addConversation, addMessage, addUserToConversation, updateGroupSelected ,deleteConversation, addMessagesByList , needScroll } = slice.actions;

// export function UpdateIsSelected(isSelected, chatId) {
//   return async (dispatch, getState) => {
//     console.log(isSelected, chatId)
//     dispatch(slice.actions.updateSelected({ isSelected, chatId, }));
//   };
// }
