
const getCurrentTimeInNanoseconds = () => {
  const now = Date.now(); // Current time in milliseconds since Unix epoch
  const highResTime = performance.now();
  console.log("highResTime = " + highResTime);
  const microseconds = now * 1000 + Math.floor(highResTime * 1000 % 1000);
  return Number(microseconds * 1000);
  
};

const nanosecondsToDate = (nanoseconds) => {
  // Convert nanoseconds to milliseconds
  const milliseconds = Math.floor(nanoseconds / 1000000);
  // Create a Date object using the milliseconds
  return new Date(milliseconds);
};

const dateToNano = (millis) => {
  console.log("dateToNano = "  + Number(millis * 1000000));
  return Number(millis * 1000000);
  
}

const nanoToDate = (nano) => {
  console.log(nano + "  nanoToDate = " + new Date(Math.floor(nano / 1000000)));
 return  new Date(Math.floor(nano / 1000000));
}

const getCurrentWeekStart = () => {
  const now = new Date();
  const dayOfWeek = now.getDay(); // get the day of the week (0 = Sunday, 1 = Monday, etc.)
  const startOfWeek = new Date(now); // copy the current date
  startOfWeek.setDate(now.getDate() - dayOfWeek); // set the date to the previous Sunday (start of the week)
  startOfWeek.setHours(0, 0, 0, 0); // set the time to midnight

  return startOfWeek;
};

const millisToDate = (millis) => {
  const date = new Date(millis);
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  return date.toLocaleDateString(undefined, options);
}


const lastMesageTimeFormat = (nanos) => {
  // Convert nanoseconds to milliseconds
  const millis = Math.floor(nanos / 1e6);

  // Create a Date object from milliseconds
  const date = new Date(millis);

  // Get today's date information
  const today = new Date();
  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();

  if (isToday) {
    // Format time as hours and minutes if the date is today
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  } else {
    // Format full date with day, month, year, and time if the date is not today
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }
}


export { getCurrentTimeInNanoseconds, nanosecondsToDate , dateToNano , nanoToDate , getCurrentWeekStart, millisToDate , lastMesageTimeFormat};
