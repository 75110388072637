// src/store/reducers/authReducer.js
import { SET_USER, LOGOUT, UPDATE_DETAILS } from "../auth/authActions";

const initialState = {
  user: null,
  name: null,
  userName: null,
  imgUrl:null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    case LOGOUT:
      return { ...state, user: null };
    case UPDATE_DETAILS:
      return {
        ...state,
        name: action.payload.name ? action.payload.name : state.name,
        userName: action.payload.username ? action.payload.username : state.userName,
        imgUrl: action.payload.img ? action.payload.img : state.imgUrl,
      };
    default:
      return state;
  }
};

export default authReducer;
